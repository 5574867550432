import React from "react";
import { FaTwitter, FaFacebook, FaLinkedin, FaGithub } from "react-icons/fa";
import { Link as NavLink } from "react-scroll";
import Fade from "react-reveal/Fade";
import Clipboard from "./Clipboard";

const Footer = () => {
  return (
    <div
      style={{
        color: "wheat",
      }}
      className="pb-0 bg-gray-800  md:pb-10"
    >
      <Fade bottom opposite>
        <div className="w-10/12 mx-auto pt-8 md:pt-16 flex-wrap flex flex-col md:flex-row  justify-between">
          <div className=" md:mt-1 mt-0  ">
            <p className="mb-0 text-sm md:text-lg md:mb-20  font-medium">
              Mr_Wealth
            </p>

            <p className="text-sm md:text-base ">
              React Project <br></br>
              &copy; Wealth Portfolio |
            </p>

            <div className='flex gap-2'>+13362103455    <Clipboard text={'+13362103455 '} color='color-white' showMessage={false}/></div>
            <div className='flex gap-2'>wealthiduwe@gmail.com    <Clipboard text={'wealthiduwe@gmail.com'} color='color-white' showMessage={false}/></div>
          </div>
          <div className="mt-5">
            <p className="text-sm md:text-base  font-medium">Quick Links</p>

            <NavLink
              className="block text-sm md:text-base  cursor-pointer"
              to="/"
              smooth={true}
            >
              Home
            </NavLink>
            <NavLink
              className="block text-sm md:text-base  cursor-pointer"
              to="About"
              smooth={true}
            >
              About
            </NavLink>
            <NavLink
              className="block text-sm md:text-base  cursor-pointer"
              to="About"
              smooth={true}
            >
              Resume
            </NavLink>
            <NavLink
              className="block text-sm md:text-base  cursor-pointer"
              to="Project"
              smooth={true}
            >
              Projects
            </NavLink>
            <NavLink
              className="block text-sm md:text-base  cursor-pointer"
              to="Contact"
              smooth={true}
            >
              Contact
            </NavLink>
          </div>

          <div className="mt-5">
            <p className=" font-medium text-sm md:text-base">Get In Touch</p>

            <p className="text-sm md:text-base ">I'll love to hear from you</p>

            <p className="flex mt-4 mb-8 md:mt-16 md:mb-0 gap-3">
              <a
                className="bg-gray-500 block hover:scale-110 transform cursor-pointer rounded-full text-white p-2"
                href="https://github.com/mrWealthid"
              >
                <FaGithub />
              </a>
              <a
                className="bg-gray-500 hover:scale-110 transform cursor-pointer block rounded-full text-white p-2"
                href="https://www.linkedin.com/in/mrwealthid"
              >
                <FaLinkedin />
              </a>{" "}
              <a
                className="bg-gray-500 block hover:scale-110 transform cursor-pointer rounded-full text-white p-2"
                href="/"
              >
                <FaTwitter />
              </a>{" "}
              <a
                className="bg-gray-500 block hover:scale-110 transform cursor-pointer rounded-full text-white p-2"
                href="/"
              >
                <FaFacebook />
              </a>
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Footer;
