import React from 'react';
import { jobsData } from './data/data';
import Job from './Job';

const Jobs = () => {
	return (
		<div
			id='Jobs'
			className='text-md border p-6 rounded-2xl flex flex-col gap-7 mt-5 text-justify'>
			{jobsData.map((job, index) => (
				<React.Fragment key={job.company}>
					<Job {...job} />
					{index !== jobsData.length - 1 && <hr />}
				</React.Fragment>
			))}
		</div>
	);
};

export default Jobs;
