import React from 'react';
import Fade from 'react-reveal/Fade';
import Angular from '../assets/Angulars.png';
import Bootstrap from '../assets/bootstrap.jpg';
import CSS from '../assets/Css.jpg';
import Firebase from '../assets/firbase.png';
import Js from '../assets/Js.png';
import ReactImg from '../assets/logo192.png';
import Postman from '../assets/postman.png';
import Redux from '../assets/Redux.png';
import Sass from '../assets/sass.png';
import Node from '../assets/nodeexpress.png';
import Typescript from '../assets/typescript.webp';
import Mongo from '../assets/mongo.png';

import Tailwind from '../assets/Tailwind.jpg';

const Stacks = () => {
	return (
		<div
			className=' flex flex-col justify-center w-10/12 mx-auto text-auto my-10 space-y-8'
			id='Stack'>
			<Fade bottom opposite>
				<h1 className='font-bold text-center text-xl md:text-2xl lg:text-3xl mt-5 font-Poppins text-gray-700'>
					Stacks I Use...
				</h1>

				{/* <Flip cascade> */}
				<div className='flex gap-3 flex-wrap  md:w-full justify-center  md:mx-auto w-full space-y-5 lg:space-y-0 space-x-4'>
					<img
						className={
							' w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40 animate-float rounded-full shadow-2xl '
						}
						src={CSS}
						alt='Css'
					/>
					<img
						className={
							' w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40 animate-refloat rounded-full shadow-2xl '
						}
						src={Sass}
						alt='Sass'
					/>
					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40 animate-float rounded-full shadow-2xl p-2'
						}
						src={Js}
						alt='Javascript'
					/>
					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-refloat rounded-full shadow-2xl p-2'
						}
						src={Tailwind}
						alt='tailwind'
					/>
					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Bootstrap}
						alt='Bootstrap'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Angular}
						alt='Angular'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Typescript}
						alt='Typescript'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Node}
						alt='Node'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Mongo}
						alt='MongoDB'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-refloat rounded-full shadow-2xl p-2'
						}
						src={ReactImg}
						alt='React'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Postman}
						alt='postman'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-refloat rounded-full shadow-2xl p-2'
						}
						src={Redux}
						alt='Redux'
					/>

					<img
						className={
							'w-32 h-32 object-fit md:w-40 md:h-40 lg:w-40 lg:h-40  animate-float rounded-full shadow-2xl p-2'
						}
						src={Firebase}
						alt='firebase'
					/>
				</div>
			</Fade>
		</div>
	);
};

export default Stacks;
