import "./App.css";
import Header from "./components/Header";
// import Navigation from "./components/Navigation";
import Stacks from "./components/Stacks";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
// import Nav from "./components/Nav";

import Footer from "./components/Footer";
import Navs from "./components/Navs";

function App() {
  return (
    <div className="App ">
     <section className='hero-style'>
         <Navs />
         <Header />
     </section>

      <About />
      <Projects />
      <Stacks />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
