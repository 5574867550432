import React, { useState } from 'react';
import {
	FaBars,
	FaTwitter,
	FaFacebook,
	FaLinkedin,
	FaGithub,
	FaTimes
} from 'react-icons/fa';
import '../App.css';
import { useTransition, animated } from 'react-spring';
// import { NavLink } from "react-router-dom";
import { Link as NavLink } from 'react-scroll';

// function Mount() {
//   const [show, set] = useState(false);
//   const transitions = useTransition(show, {
//     from: { opacity: 0 },
//     enter: { opacity: 1 },
//     leave: { opacity: 0 },
//     reverse: show,
//     delay: 200,

//     onRest: () => set(!show),
//   });
//   return transitions(
//     (styles, item) => item && <animated.div style={styles}>✌️</animated.div>
//   );
// }

const Navs = () => {
	const [menu, setMenu] = useState(false);

	const menuContent = (
		<div className='flex flex-col min-h-screen'>
			<FaTimes
				onClick={() => setMenu(false)}
				className='absolute right-0 text-2xl m-4'
			/>

			<h1 className='mt-24 text-center text-3xl '>Welcome</h1>

			<div className='w-full mt-4 space-y-5 '>
				<NavLink
					onClick={() => setMenu(false)}
					className=' py-2 text-center block text-sm border-b'
					to='/'
					smooth={true}>
					Home
				</NavLink>

				<NavLink
					onClick={() => setMenu(false)}
					className=' py-2 text-center block text-sm border-b'
					to='About'
					smooth={true}>
					About
				</NavLink>

				<NavLink
					onClick={() => setMenu(false)}
					className=' py-2 text-center block text-sm border-b'
					to='Jobs'
					smooth={true}>
					Resume
				</NavLink>

				<NavLink
					onClick={() => setMenu(false)}
					className=' py-2 text-center block text-sm border-b'
					to='Projects'
					smooth={true}>
					Projects
				</NavLink>

				<NavLink
					onClick={() => setMenu(false)}
					className=' py-2 text-center block text-sm border-b'
					to='Contact'
					smooth={true}>
					Contact
				</NavLink>
			</div>

			<div className='flex-1 flex justify-center items-center p-6  w-full space-x-6'>
				<a href='https://github.com/mrWealthid'>
					<FaGithub />
				</a>{' '}
				<FaTwitter /> <FaFacebook />
				<a href='https://www.linkedin.com/in/mrwealthid'>
					{' '}
					<FaLinkedin />
				</a>{' '}
			</div>
		</div>
	);

	const transitions = useTransition(menu, {
		from: { opacity: 0, translateX: '-100%' },
		enter: { opacity: 1, translateX: '0%' },
		leave: { opacity: 0, translateX: '-100%' }
	});

	return (
		<div
			className='text-gray-600 border-b-2 z-50 sticky'
			style={{
				background: '#F0F4FD'
			}}>
			<div className='flex  w-10/12 mx-auto py-6 items-center justify-between'>
				<div>
					<span className='h-3 w-3 animate-bounce mx-1 bg-red-700 inline-block rounded-full'></span>
					<span className='h-3 w-3 animate-rebounce mx-1 bg-red-700 inline-block rounded-full'></span>
					<span className='h-3 w-3 mx-1 animate-bounce  bg-red-700 inline-block rounded-full'></span>
					<span className='h-3 w-3 mx-1 animate-rebounce bg-red-700 inline-block rounded-full'></span>
				</div>

				<div className=' hidden sm:block  space-x-6'>
					<NavLink className=' cursor-pointer' to='/' smooth={true}>
						Home
					</NavLink>
					<NavLink
						className=' cursor-pointer'
						to='About'
						smooth={true}>
						About
					</NavLink>
					<NavLink
						className=' cursor-pointer'
						to='Jobs'
						smooth={true}>
						Resume
					</NavLink>
					<NavLink
						className=' cursor-pointer'
						to='Projects'
						smooth={true}>
						Projects
					</NavLink>
					<NavLink
						className=' cursor-pointer'
						to='Contact'
						smooth={true}>
						Contact
					</NavLink>
				</div>

				<div className='sm:hidden'>
					<FaBars onClick={() => setMenu(!menu)} />
				</div>

				{transitions(
					(styles, item) =>
						item && (
							<animated.div
								style={styles}
								className='menu sm:hidden flex flex-col w-full min-h-screen space-y-5 items-center absolute left-0 z-50 bg-[f0f4fd] top-0 border-black border-r-2'
								onClick={() => setMenu(!menu)}>
								{menuContent}
							</animated.div>
						)
				)}
			</div>
		</div>
	);
};

export default Navs;
