import React from "react";
import { FaLink } from "react-icons/fa";

const Cards = ({ projectLink, imgLink, alt, projectTitle, projectDesc }) => {
  return (
    
      <a href={projectLink} className="h-72 block">
        <div className="card border shadow-2xl max-w-sm h-full transform rounded-2xl overflow-hidden">
          <img src={imgLink} alt={alt} />
          <div className="p-4 text-center">
            <p className=" justify-center items-center flex">
              {projectTitle}
              <span className="mx-3">
                <FaLink fontSize="small" />
              </span>
            </p>
            <small>{projectDesc}</small>
          </div>
        </div>
      </a>
    
  );
};

export default Cards;
