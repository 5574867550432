import React from "react";
import Input from "./formInput";

const ContactForm = () => {
  return (
    <form
      className="w-full flex-1 md:w-full md:w-10/12 mx-auto"
      name="contact"
      method="post"
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="md:max-w-md">
        <div className="grid grid-cols-1 gap-6">
          <Input
            type="text"
            Content="Name"
            name="name"
            placeholder="Enter Name"
            focus="focus"
          />

          <Input
            type="email"
            Content="Email Address"
            placeholder="john@example.com"
            name="email"
          />

          <label className="block">
            <span className="text-gray-700">Get In Touch</span>
            <textarea
              className="mt-1 block w-full p-4 rounded-md bg-gray-100 border-transparent outline-none"
              rows="4"
              name="message"
              required
              placeholder="Enter Message..."
            ></textarea>
          </label>

          <div>
            <button
              style={{ color: "wheat" }}
              className="bg-gray-700 text-white text-sm md:text-base rounded-full hover:bg-gray-500 py-3 px-7"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
