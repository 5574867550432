import React from 'react';

const Job = ({
	startDate,
	endDate,
	company,
	position,
	stack,
	milestone,
	duties
}) => {
	return (
		<section className='flex flex-col  justify-around md:flex-row'>
			<article className='flex md:flex-col'>
				<p>{startDate} - </p>
				<p>{endDate}</p>
			</article>
			<div className='sm:max-w-lg lg:w-10/12 lg:max-w-2xl text-md flex flex-col gap-2'>
				<p className='font-medium text-lg'>{company}</p>
				<p className='font-medium'>{position}</p>
				<p className='text-sm font-light'>{stack}</p>
				<span className='text-sm'>{milestone}</span>
				<hr />
				<ul className='list-circle mt-1 font-light flex flex-col gap-1'>
					{duties.map((duty) => (
						<li>{duty}</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default Job;
