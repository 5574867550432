import React from "react";
import ContactForm from "./ContactForm";
import ContactContent from "./ContactContent";
import Fade from "react-reveal/Fade";

const Contact = () => {
  return (
    <div
      className="lg:min-h-screen flex w-10/12 mx-auto justify-center item-center mb-10"
      id="Contact"
    >
      <Fade bottom opposite>
        <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-8 lg:space-y-0">
          <ContactForm />
          <ContactContent />
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
